<template>
    <div id="uma-penca-home-vue" class="new-home">
        <section class="principal-banner">
            <div
                v-for="(banner, index) in orderedBanners"
                :class="index === 0 ? 'first-item' : 'second-item'"
                :style="{ ...getBaseStyle(banner.id, index), ...getTextColor(banner.id, index) }"
            >
                <div class="content d-flex flex-align-center item">
                    <div class="text-block">
                        <h1 v-if="index === 0">{{ banner.title }}</h1>
                        <h2 class="default-h1" v-else>{{ banner.title }}</h2>
                        <p class="mt-3">
                            {{ banner.text }}
                        </p>
                        <div class="d-flex flex-align-center mt-4">
                            <button class="btn btn-lg mr-4" @click="goTo(banner.redirect)">
                                {{ banner.button }}
                            </button>
                            <a
                                @click="goToElement(banner.id)"
                                :style="{
                                    ...getTextColor(banner.id, index),
                                }"
                                >{{ banner.link }}</a
                            >
                        </div>
                    </div>
                    <picture>
                        <source media="(min-width: 576px)" :srcset="banner.img_mobile" />
                        <img
                            :src="index === 0 ? banner.img_first : banner.img_second"
                            :alt="banner.alt"
                            class="img-responsive"
                        />
                    </picture>
                </div>
            </div>
        </section>

        <section class="content passos" id="passo-a-passo">
            <h2 class="text-center mb-5 mt-6" v-if="activeType === 1">Crie sua loja em 4 passos</h2>
            <h2 class="text-center mb-5 mt-6" v-else>Personalize em 4 passos</h2>
            <div class="d-flex flex-justify-center mb-5 mt-3">
                <div class="toogle">
                    <div
                        class="toggleAbsolute"
                        :class="[activeType === 1 ? 'left' : 'right']"
                    ></div>
                    <h3
                        :class="[activeType === 1 ? 'toogle2' : 'toogle1']"
                        class="toogle1 cursor-pointer"
                        @click="activeType = 1"
                        :style="activeType === 2 ? { opacity: 0.3 } : {}"
                    >
                        Criação de loja
                    </h3>
                    <h3
                        :class="[activeType === 2 ? 'toogle2' : 'toogle1']"
                        class="cursor-pointer"
                        @click="activeType = 2"
                        :style="activeType === 1 ? { opacity: 0.3 } : {}"
                    >
                        Personalização
                    </h3>
                </div>
            </div>

            <div class="hidden-xs-down">
                <transition name="fade">
                    <div key="1" v-if="activeType === 1" class="passo-a-passo">
                        <div class="item" v-for="(item, index) in filteredSteps">
                            <img :src="item.img" :alt="item.title" />
                            <h3>{{ index + 1 }}. {{ item.title }}</h3>
                            <p>
                                {{ item.text }}
                            </p>
                        </div>
                    </div>
                </transition>
                <transition name="fade">
                    <div key="2" v-if="activeType === 2" class="passo-a-passo">
                        <div class="item" v-for="(item, index) in filteredSteps">
                            <img :src="item.img" :alt="item.title" />
                            <h3>{{ index + 1 }}. {{ item.title }}</h3>
                            <p>
                                {{ item.text }}
                            </p>
                        </div>
                    </div>
                </transition>
            </div>

            <div class="hidden-xs-up">
                <transition name="fade">
                    <div class="d-flex mt-5 w-full" v-if="activeType === 1">
                        <ul
                            id="img-list"
                            style="flex: 100%"
                            class="step-by-step horizontal-list no-scrollbar mb-3"
                            @scroll="scrollEvent = $event"
                        >
                            <li v-for="(item, index) in filteredSteps">
                                <div
                                    class="item"
                                    style="min-height: 436px; background-color: #f9e5e9"
                                >
                                    <img :src="item.img" :alt="item.title" />
                                    <h1 class="text-center">{{ index + 1 }}. {{ item.title }}</h1>
                                    <p class="text-center">
                                        {{ item.text }}
                                    </p>
                                </div>
                            </li>
                            <li v-if="activeType === 2" v-for="(item, index) in filteredSteps">
                                <div
                                    class="item"
                                    style="min-height: 358px; background-color: #e7efc9"
                                >
                                    <img :src="item.img" :alt="item.title" />
                                    <h1 class="text-center">{{ index + 1 }}. {{ item.title }}</h1>
                                    <p class="text-center">
                                        {{ item.text }}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="d-flex mt-5 w-full" v-if="activeType === 2">
                        <ul
                            id="img-list"
                            style="flex: 100%"
                            class="step-by-step horizontal-list no-scrollbar mb-3"
                            @scroll="scrollEvent = $event"
                        >
                            <li v-for="(item, index) in filteredSteps">
                                <div
                                    class="item"
                                    style="min-height: 358px; background-color: #e7efc9"
                                >
                                    <img :src="item.img" :alt="item.title" />
                                    <h1 class="text-center">{{ index + 1 }}. {{ item.title }}</h1>
                                    <p class="text-center">
                                        {{ item.text }}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </transition>
                <HorizontalScrollbar
                    :scroll-event="scrollEvent"
                    :button-height="110"
                    :id-list="'img-list'"
                />
            </div>
        </section>

        <section class="content">
            <h2 class="text-center mb-5 mt-6 default-h1">Materialize suas ideias</h2>
            <div class="listagem-produtos hidden-xs-down">
                <div class="item-produto" v-for="product in products">
                    <div>
                        <picture>
                            <source media="(min-width: 576px)" :srcset="product.img_mobile" />
                            <img
                                :data-src="product.img_desktop"
                                :alt="product.title"
                                class="img-responsive lazy lazy-fade"
                            />
                        </picture>
                    </div>
                    <div>
                        <h3 class="default-h2">{{ product.title }}</h3>
                        <ul>
                            <li>
                                <p>{{ product.text1 }}</p>
                            </li>
                            <li>
                                <p>{{ product.text2 }}</p>
                            </li>
                            <li>
                                <p>{{ product.text3 }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="hidden-xs-up listagem-produtos-mobile">
                <div class="first-item">
                    <div>
                        <picture>
                            <source media="(min-width: 576px)" :srcset="products[0].img_mobile" />
                            <img
                                :data-src="products[0].img_desktop"
                                :alt="products[0].title"
                                class="img-responsive lazy lazy-fade"
                            />
                        </picture>
                    </div>
                    <div>
                        <h2>{{ products[0].title }}</h2>
                        <ul>
                            <li>
                                <p>{{ products[0].text1 }}</p>
                            </li>
                            <li>
                                <p>{{ products[0].text2 }}</p>
                            </li>
                            <li>
                                <p>{{ products[0].text3 }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="listagem">
                    <div class="item-produto" v-for="product in products.slice(1)">
                        <picture>
                            <source media="(min-width: 576px)" :srcset="product.img_mobile" />
                            <img
                                :data-src="product.img_desktop"
                                :alt="product.title"
                                class="img-responsive lazy lazy-fade"
                            />
                        </picture>
                        <div>
                            <h2>{{ product.title }}</h2>
                            <ul>
                                <li>
                                    <p>{{ product.text1 }}</p>
                                </li>
                                <li>
                                    <p>{{ product.text2 }}</p>
                                </li>
                                <li>
                                    <p>{{ product.text3 }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--        <section class="depoimentos">-->
        <!--            <h1 class="text-center mb-5">Com a palavra, nossa comunidade</h1>-->
        <!--            <div class="content listagem-depoimentos hidden-xs-down">-->
        <!--                <div class="item-depoimento" v-for="depoimento in depoiments">-->
        <!--                    <p>{{ depoimento.text }}</p>-->
        <!--                    <div class="d-flex flex-align-center mt-3">-->
        <!--                        <img :data-src="depoimento.img" class="mr-3 lazy lazy-fade" :alt="depoimento.author" />-->
        <!--                        <div class="author">-->
        <!--                            <h1>{{ depoimento.author }}</h1>-->
        <!--                            <p>{{ depoimento.office }}</p>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->

        <!--            <div class="content hidden-xs-up">-->
        <!--                <div class="d-flex mt-5 w-full">-->
        <!--                    <ul-->
        <!--                        id="img-list"-->
        <!--                        style="flex: 100%"-->
        <!--                        class="depoimentos-mobile horizontal-list no-scrollbar mb-3"-->
        <!--                        @scroll="scrollEvent = $event"-->
        <!--                    >-->
        <!--                        <li v-for="depoimento in depoiments">-->
        <!--                            <div class="item-depoimento">-->
        <!--                                <p>{{ depoimento.text }}</p>-->
        <!--                                <div class="d-flex flex-align-center mt-3">-->
        <!--                                    <img-->
        <!--                                        :data-src="depoimento.img"-->
        <!--                                        class="mr-3 lazy lazy-fade"-->
        <!--                                        :alt="depoimento.author"-->
        <!--                                    />-->
        <!--                                    <div class="author">-->
        <!--                                        <h1>{{ depoimento.author }}</h1>-->
        <!--                                        <p>{{ depoimento.office }}</p>-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </li>-->
        <!--                    </ul>-->
        <!--                </div>-->
        <!--                <HorizontalScrollbar-->
        <!--                    :scroll-event="scrollEvent"-->
        <!--                    :button-height="110"-->
        <!--                    :id-list="'img-list'"-->
        <!--                />-->
        <!--            </div>-->
        <!--        </section>-->

        <section class="content">
            <h2 class="text-center mb-5 mt-6 default-h1">Suas ideias mudam o mundo!</h2>
            <div class="dados-bacanas hidden-xs-down">
                <div class="divisoes">
                    <div
                        v-for="data in coolData.slice(0, 2)"
                        class="item"
                        :style="{
                            backgroundImage: `url(${data.img_background})`,
                            backgroundSize: 'cover',
                        }"
                    >
                        <h2>{{ data.title }}</h2>
                        <h3>{{ data.info }}</h3>
                        <p>{{ data.description }}</p>
                    </div>
                </div>
                <div class="divisoes">
                    <div
                        v-for="data in coolData.slice(2, 5)"
                        class="item"
                        :style="{
                            backgroundImage: `url(${data.img_background})`,
                            backgroundSize: 'cover',
                        }"
                    >
                        <h2>{{ data.title }}</h2>
                        <h3>{{ data.info }}</h3>
                        <p>{{ data.description }}</p>
                    </div>
                </div>
            </div>
            <div class="dados-bacanas-mobile mt-5 mb-5 hidden-xs-up">
                <div class="divisoes">
                    <div
                        v-for="data in coolData"
                        class="item"
                        :style="{
                            backgroundImage: `url(${data.img_background})`,
                            backgroundSize: 'cover',
                        }"
                    >
                        <h2>{{ data.title }}</h2>
                        <h3>{{ data.info }}</h3>
                        <p>{{ data.description }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section style="background-color: #f7f7f5">
            <div class="content">
                <div class="fabrica">
                    <div>
                        <h2 class="default-h1">
                            Nunca foi tão fácil colocar suas ideias no mundo!
                        </h2>
                        <p>
                            Nossa estrutura print on demand tem experiência e uma história de mais
                            de 16 anos ;)
                        </p>
                        <p>
                            Em Uma Penca, seus produtos são confeccionados lado a lado com as peças
                            da Chico Rei, e-commerce nacional líder no segmento de camisetas
                            criativas e personalizadas
                        </p>
                    </div>
                    <img
                        data-src="https://uma-penca.imgix.net/images/site/produtos-personalizados/UP_home_sec06_fabrica_050824.png?auto=compress,format&q=65&fit=crop&w=640&h=380"
                        class="img-responsive lazy lazy-fade"
                        alt="Estrutura Chico Rei"
                    />
                </div>
            </div>
        </section>

        <section class="content">
            <h2 class="text-center mb-5 mt-5 default-h1">Perguntas frequentes</h2>
            <div class="d-flex flex-column flex-justify-center flex-align-center mb-7">
                <div class="fac-item" v-for="item in fac">
                    <div
                        class="d-flex flex-justify-between flex-align-center cursor-pointer"
                        @click="item.open = !item.open"
                    >
                        <h3>{{ item.title }}</h3>
                        <i v-if="!item.open" class="icon-cr icon-chevron-down cursor-pointer"></i>
                        <i v-else class="icon-cr icon-chevron-up cursor-pointer"></i>
                    </div>
                    <div v-if="item.open">
                        <a v-if="item.link" :href="item.link" target="_blank"
                            ><p>{{ item.description }}</p></a
                        >
                        <p v-else>{{ item.description }}</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import HorizontalScrollbar from '../../common/container/HorizontalScrollbar.vue';
    import { lazyLoadInstance } from '../../../common/lazyload';

    export default {
        name: 'Home',

        components: { HorizontalScrollbar },

        data() {
            return {
                activeType: 1,
                initialBanners: [
                    {
                        id: 1,
                        title: 'Crie sua loja e venda online sem estoque',
                        text: 'Crie e venda uma penca de produtos sem se preocupar com produção, logística, atendimento e tecnologia. Tudo isso numa plataforma simples, intuitiva e gratuita! Você tem a ideia, a gente cuida da produção à entrega ;)',
                        button: 'Criar loja',
                        alt: 'Criar loja',
                        redirect: '/criar-loja',
                        link: 'Passo a passo',
                        img_first:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec01_principal_01_050824.png?auto=compress,format&q=100',
                        img_second:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec01_secundario_01_050824.png?auto=compress,format&q=100',
                    },
                    {
                        title: 'Personalize sua camiseta',
                        text: 'Crie camisetas personalizadas exclusivas com suas ideias mais originais! Na personalização você tem acesso à nossa tecnologia de produção em poucos cliques ;)',
                        button: 'Personalizar camiseta',
                        alt: 'Personalizar camiseta',
                        redirect: '/produtos-personalizados',
                        link: 'Passo a passo',
                        img_first:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec01_principal_02.png?auto=compress,format&q=100',
                        img_second:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec01_secundrio_02.png?auto=compress,format&q=100',
                        id: 2,
                    },
                ],

                products: [
                    {
                        img_desktop:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec06_produtos_01.png?auto=compress,format&q=65&fit=crop&w=205',
                        img_mobile:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec06_produtos_01.png?auto=compress,format&q=65',
                        title: 'Camiseta',
                        text1: 'Estampa tradicional',
                        text2: '100% algodão',
                        text3: 'Regular e Baby look',
                    },
                    {
                        img_desktop:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec03_produtos_02.png?auto=compress,format&q=65&fit=crop&w=205',
                        img_mobile:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec03_produtos_02.png?auto=compress,format&q=65',
                        title: 'Camiseta',
                        text1: 'Estampa mini',
                        text2: '100% algodão',
                        text3: 'Regular e Baby look',
                    },
                    {
                        img_desktop:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec03_produtos_03_050824.png?auto=compress,format&q=65&fit=crop&w=205',
                        img_mobile:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec03_produtos_03_050824.png?auto=compress,format&q=65',
                        title: 'Caneca',
                        text1: 'Cerâmica',
                        text2: '325 ml',
                        text3: 'Estampada por termo transferência',
                    },
                    {
                        img_desktop:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec03_produtos_04_050824.png?auto=compress,format&q=65&fit=crop&w=205',
                        img_mobile:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec03_produtos_04_050824.png?auto=compress,format&q=65',
                        title: 'Ecobag',
                        text1: '100% algodão',
                        text2: '45 cm de altura x 40 cm de largura x 27 cm de alça',
                        text3: 'Impressão Direct to Film',
                    },
                    {
                        img_desktop:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec03_produtos_05_050824.png?auto=compress,format&q=65&fit=crop&w=205',
                        img_mobile:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec03_produtos_05_050824.png?auto=compress,format&q=65',
                        title: 'Pôsteres',
                        text1: 'Papel fotográfico 260g',
                        text2: 'A5, A4 e A3',
                        text3: 'Enviados em tubetes',
                    },
                ],

                depoiments: [
                    {
                        text: 'Várias pessoas nos pediam produtos colecionáveis com algumas das frases e reflexões do Prof. Cortella, para elas mesmas terem ou presentearem. Com a parceria de Uma Penca isso foi possível de um jeito criativo, e com excelente qualidade e estratégia!',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/store/8153/9d5a12e3-ab79-476f-8ea0-8b437b7bbebb.png?w=100&h=100&auto=compress,format&q=65&fit=crop&w=56&h=56',
                        author: 'André Cortella',
                        office: 'Diretor Comercial da Agência OWLGA e curador da Loja Especial Cortella',
                    },
                    {
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tortor erat, aliquam at enim a, egestas tempor ex. Aliquam nec sapien ut nulla gravida mattis non id magna. Duis dictum lacus eu erat hendrerit.',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/store/8153/9d5a12e3-ab79-476f-8ea0-8b437b7bbebb.png?w=100&h=100&auto=compress,format&q=65&fit=crop&w=56&h=56',
                        author: 'Pedro Costa',
                        office: 'Ilustrador',
                    },
                    {
                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tortor erat, aliquam at enim a, egestas tempor ex. Aliquam nec sapien ut nulla gravida mattis non id magna. Duis dictum lacus eu erat hendrerit.',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/store/8153/9d5a12e3-ab79-476f-8ea0-8b437b7bbebb.png?w=100&h=100&auto=compress,format&q=65&fit=crop&w=56&h=56',
                        author: 'Mario Sergio Cortella',
                        office: 'Filósofo e Escritor',
                    },
                ],

                coolData: [
                    {
                        title: 'Pelo mundo',
                        info: '+40.000',
                        description: 'Pedidos entregues.',

                        img_background:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec05_dados_01_050824.png?auto=compress,format&q=65',
                    },
                    {
                        title: 'Nossa comunidade',
                        info: '+18.000',
                        description: 'Lojas cadastradas.',
                        img_background:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec05_dados_02_050824.png?auto=compress,format&q=65',
                    },
                    {
                        title: 'Impacto socioambiental',
                        info: '5',
                        description:
                            'Instituições impactadas através das doações realizadas pelas vendas na plataforma.',
                        img_background:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec05_dados_03_050824.png?auto=compress,format&q=65',
                    },
                    {
                        title: 'Pelo Brasil',
                        info: '26',
                        description:
                            'Estados com lojistas, estamos em todo o território brasileiro.',
                        img_background:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec05_dados_04_050824.png?auto=compress,format&q=65',
                    },
                    {
                        title: 'Nós temos o borogodó',
                        info: '94%',
                        description: 'Dos consumidores comprariam novamente.',

                        img_background:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec05_dados_05_050824.png?auto=compress,format&q=65',
                    },
                ],

                fac: [
                    {
                        title: 'O que é Uma Penca?',
                        description:
                            'Uma Penca é uma plataforma online que oferece a estrutura completa para quem deseja vender ou personalizar produtos de moda e lifestyle, sem se preocupar com produção, logística e tecnologia.',
                        open: false,
                        link: null,
                    },
                    {
                        title: 'É uma plataforma gratuita?',
                        description:
                            'Sim! Você pode se cadastrar, criar sua loja e vender sem nenhum custo. O mesmo vale para personalização, você só paga pelo produto que decidir personalizar ;) ',
                        open: false,
                        link: null,
                    },
                    {
                        title: 'Criei uma loja e agora?',
                        description:
                            'Com a loja criada, é hora de desenvolver suas estampas e divulgar.  Aprenda mais e leia nossas dicas no nosso blog ;) ',
                        open: false,
                        link: 'https://blog.umapenca.com/?utm_source=home_fac&utm_medium=link_fac&utm_id=home',
                    },
                    {
                        title: 'Os produtos vão com a marca de UP?',
                        description:
                            'Não. A produção é nossa, a assinatura é sua! Nenhum de nossos produtos são etiquetados com nossa marca.',
                        open: false,
                        link: null,
                    },
                    {
                        title: 'Quais produtos posso vender?',
                        description:
                            'Nosso catálogo segue em constante atualização. Atualmente, você pode personalizar camisetas e vender camisetas, canecas, ecobags, imãs, bottons e pôsteres ;)',
                        open: false,
                        link: null,
                    },
                    {
                        title: 'Como vou receber o dinheiro da venda dos produtos?',
                        description:
                            'Você receberá os valores diretamente das operadoras na sua conta bancária cadastrada. O valor estará disponível para acessá-lo do mesmo modo como o cliente pagou. ;)',
                        open: false,
                        link: null,
                    },
                    {
                        title: 'O que é uma plataforma de print on demand? ',
                        description:
                            'Uma plataforma de impressão sob demanda oferece um modelo de negócios que te permite criar os produtos com base na demanda. Uma alternativa sustentável para quem deseja vender sem estoque.',
                        open: false,
                        link: null,
                    },
                ],

                steps: [
                    {
                        title: 'Cadastre-se',
                        text: 'Você pode se cadastrar como pessoa física ou jurídica. Depois é só criar sua loja: escolha seu nome e sua url',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec02_loja_01.png?auto=compress,format&q=100',
                    },
                    {
                        title: 'Crie seu produto',
                        text: 'Loja cadastrada, agora é com você! Coloque a criatividade para funcionar e crie a estampa dos seus produtos.',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec02_loja_02.png?auto=compress,format&q=100',
                    },
                    {
                        title: 'Configure sua loja',
                        text: 'Cadastre seus produtos, organize as categorias da sua loja e escolha as instituições parceiras que você vai apoiar',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec02_loja_03.png?auto=compress,format&q=100',
                    },
                    {
                        title: 'Venda',
                        text: 'Voilà! Tá tudo pronto para começar a vender! Agora é só divulgar seus produtos, a gente cuida da produção à entrega ;)',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec02_loja_04.png?auto=compress,format&q=100',
                    },
                    {
                        title: 'Escolha',
                        text: 'Selecione entre nossos modelos de estampa: mini ou tradicional.',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec02_personalizacao_01.png?auto=compress,format&q=100',
                    },
                    {
                        title: 'Confira',
                        text: 'Confira as dimensões, os termos de uso e as regras de direitos autorais.',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec02_personalizacao_02.png?auto=compress,format&q=100',
                    },
                    {
                        title: 'Envie',
                        text: 'Aqui você já consegue visualizar como sua camiseta vai ficar.',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec02_personalizacao_03.png?auto=compress,format&q=100',
                    },
                    {
                        title: 'Finalize ;)',
                        text: 'Tudo pronto, agora é só esperar!',
                        img:
                            this.$scope.CDN_IMAGES +
                            '/images/site/produtos-personalizados/UP_home_sec02_personalizacao_04.png?auto=compress,format&q=100',
                    },
                ],
                scrollEvent: null,
            };
        },

        mounted() {
            lazyLoadInstance.update();
            if (this.$scope.homeDisplayOrder) {
                this.activeType = 2;
            }
        },

        computed: {
            filteredSteps() {
                if (this.activeType === 1) {
                    // Retorna os primeiros 4 itens
                    return this.steps.slice(0, 4);
                } else {
                    // Retorna os últimos 4 itens
                    return this.steps.slice(-4);
                }
            },

            orderedBanners() {
                if (this.$scope.homeDisplayOrder) {
                    return [...this.initialBanners].reverse();
                } else {
                    return this.initialBanners;
                }
            },
        },

        methods: {
            goToElement(id) {
                if (id === 1) {
                    this.activeType = 1;
                } else {
                    this.activeType = 2;
                }

                const element = document.getElementById('passo-a-passo');
                const rect = element.getBoundingClientRect();
                const offsetTop = window.pageYOffset + rect.top - 100;

                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
            },

            goTo(redirect) {
                window.open(redirect, '_blank');
            },

            getBaseStyle(id, index) {
                if (id === 1 && index === 0) {
                    return { background: 'linear-gradient(to top right, #FECE00, #FFE927)' };
                } else if (id === 2 && index === 0) {
                    return { background: 'linear-gradient(to top right, #114610, #005422)' };
                } else {
                    return {};
                }
            },
            getTextColor(id, index) {
                if (id === 2 && index === 0) {
                    return { color: '#F7F7F5 !important' };
                } else {
                    return {};
                }
            },
        },
    };
</script>
